<template>
  <v-form
    :id="id"
    ref="form"
    :item="item"
  >
    <v-row justify="center">
      <v-col sm="6">
        <v-card>
          <v-card-text>
            <v-col>
              <v-text-field
                v-model="user.name"
                filled
                label="name"
                :rules="rules.name"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="user.email"
                filled
                :error-messages="errorMessages.email"
                label="email"
                :rules="emailRules"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="user.password"
                filled
                type="password"
                label="password"
                :rules="rules.password"
              />
            </v-col>
            <v-btn
              depressed
              color="primary"
              @click="createNewUser"
            >
              Створити
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-alert
      v-if="isCreated"
      class="message-success"
      dense
      type="success"
    >
      Користувача успішно створено
    </v-alert>
  </v-form>
</template>

<script>
import User from "../../models/User.ts";

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["id", "title", "item"],
  data: () => ({
    user: new User(),
    errorMessages: {},
    isCreated: false,
    rules: {
      name: [v => !!v || "Введіть Ім'я Користувача"],
      password: [v => !!v || "Введіть пароль Користувача"],
    },
    emailRules: [
      v => !!v || 'Введіть email Користувача',
      v => /.+@.+/.test(v) || 'Email не коректний!',
    ],
  }),
  methods: {
    async createNewUser() {
      if (this.$refs.form.validate()) {
        try {
          await this.user.create();
          this.$refs.form.reset();
          this.isCreated = true;
          setTimeout(() => {
            this.isCreated = false;
            this.$router.push({path: `/users`});
          }, 1000);
        } catch (e) {
          if (e.errors) {
            this.errorMessages = e.errors;
            return;
          }
          this.errorMessages = {email: "Користувач з таким Email вже існує"};
        }
      }
    },
  },
};
</script>

<style>
.message-success {
  display: block;
  position: absolute;
  bottom: 70px;
  width: 98%;
  text-align: center;
  margin: auto;
}
</style>
