<template>
  <div class="">
    <h2 class="ml-5 mb-6">
      Нова мова
    </h2>

    <languages-form :itemid="item" />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["title", "item"],
};
</script>
