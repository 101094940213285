<template>
  <locations-form :itemid="item" />
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["title", "item"],
};
</script>
