<template>
  <div>
    <v-card-title class="text--primary mb-2">
      Редагувати користувача
    </v-card-title>
    <v-form
      :id="id"
      ref="form"
    >
      <v-row justify="center">
        <v-col sm="6">
          <v-card>
            <v-card-text>
              <v-col>
                <v-text-field
                  v-model="userUpdate.name"
                  filled
                  label="name"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="userUpdate.email"
                  filled
                  :error-messages="errorMessages.email"
                  label="email"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="userPassword"
                  filled
                  label="password"
                />
              </v-col>
              <v-btn
                depressed
                color="primary"
                @click="updateUser"
              >
                Змінити
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-alert
        v-if="isUpdated"
        class="message-success"
        dense
        type="success"
      >
        Користувача успішно змінено
      </v-alert>
    </v-form>
  </div>
</template>

<script>
import UserRepository from "../../repositories/UserRepository";
import User from "../../models/User";
import {mapActions} from "vuex";

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["id", "title", "item"],
  data: () => ({
    user: new User(),
    isUpdated: false,
    userRepository: new UserRepository(),
    userUpdate: {},
    errorMessages: {},
    userPassword: ''
  }),
  async created() {
    this.userUpdate = await this.userRepository.one(this.$route.params.id);
  },
  methods: {
    async updateUser() {
      try {
        this.user.uuid = this.$route.params.id;
        this.user.name = this.userUpdate.name;
        this.user.email = this.userUpdate.email;
        this.user.password = this.userPassword;
        await this.user.update();
        this.isUpdated = true;
        setTimeout(() => {
          this.isUpdated = false;
          this.$router.push({path: `/users`});
        }, 1000);
      } catch (e) {
        if (e.errors) {
          this.errorMessages = e.errors;
          return;
        }
        this.errorMessages = {email: "Користувач з таким Email вже існує"};
      }
    },
  },
};
</script>

<style>
.message-success {
  display: block;
  position: absolute;
  bottom: 70px;
  width: 98%;
  text-align: center;
  margin: auto;
}
</style>
