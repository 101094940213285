import jwtDecode from 'jwt-decode';
import RefreshTokenException from '../models/Exceptions/RefreshTokenException';
import InvalidTokenException from '../models/Exceptions/InvalidTokenException';
// @ts-ignore
import refreshTokenMutation from '../gql/users/mutations/refreshToken.graphql';
import User from '../models/User';

export interface JsonWebToken {
  exp: string;
}

export default class JWT {
  /**
   * Name of JWT token
   *
   * @type {string}
   */
  static tokenName = 'accessToken';

  /**
   * Name of JWT refresh token
   *
   * @type {string}
   */
  static refreshTokenName = 'refreshToken';


  static username = 'username';

  /**
   * Retrieves token from storage
   *
   * @param {string} item
   * @returns {string | null}
   */
  static storageGet(item: string) {
    return localStorage.getItem(item) || null;
  }

  /**
   * Saves token to storage
   *
   * @param {string} item
   * @param {string} value
   */
  static storageSet(item: string, value: string) {
    localStorage.setItem(item, value);
  }

  /**
   * Removes token from localStorage
   *
   * @param {string} item
   */
  static storageRemove(item: string) {
    localStorage.removeItem(item);
  }

  /**
   * Regenerates a new token via API and stores in a storage
   *
   * @returns {Promise<*>}
   * @throws {RefreshTokenException}
   */
  static async apiRegenerate() {
    const refreshToken = JWT.storageGet(JWT.refreshTokenName);
    let accessToken;
    let newRefreshToken;
    if (!refreshToken) {
      // No refresh token, clear `accessToken` too and throw
      JWT.clear();
      throw new RefreshTokenException(new Error('No refresh token available.'));
    }

    try {
      // Retrieve token from API using refreshToken
      ({
        accessToken,
        refreshToken: newRefreshToken,
      } = (await new User().fetchToken(refreshTokenMutation, { refreshToken })) as {
        accessToken: string,
        refreshToken: string,
        expiresIn: string,
      });
      // And store it
      JWT.storageSet(JWT.tokenName, accessToken);
      JWT.storageSet(JWT.refreshTokenName, newRefreshToken);
    } catch (e) {
      // Unable to refresh token, clear and throw
      JWT.clear();
      throw new RefreshTokenException(e);
    }

    return accessToken;
  }

  /**
   * Retrieves a token from a storage
   *
   * @returns {string | null}
   */
  static getToken() {
    return JWT.storageGet(JWT.tokenName);
  }

  /**
   * Returns true if token is expired
   *
   * @returns {boolean}
   */
  static isExpired() {
    // If token's TTL is in the past or null
    const expiryDate = JWT.getExpiryDate();

    return !expiryDate || (new Date()).getTime() >= expiryDate.getTime();
  }

  /**
   * Returns token expiration date from JWT header
   *
   * @link https://jwt.io/
   * @returns {*}
   */
  static getExpiryDate() {
    const encodedToken = JWT.getToken();

    if (!encodedToken) {
      return null;
    }
    const token: JsonWebToken = jwtDecode(encodedToken);

    // If we cannot determine expiration date,
    // assume it as some default date in the past
    if (!token || !token.exp) return new Date('2000-01-01');

    return new Date(+token.exp * 1000);
  }

  /**
   * Retrieves and verifys a new access token
   *
   * @returns {Promise<void>}
   */
  static async regenerate() {
    await JWT.apiRegenerate();
    JWT.verify();
  }

  /**
   * Checks if token is set and not expired
   *
   * @throws {InvalidTokenException}
   */
  static verify() {
    const token = JWT.getToken();

    if (!token || JWT.isExpired()) {
      throw new InvalidTokenException();
    }
  }

  static clear() {
    JWT.storageRemove(JWT.tokenName);
    JWT.storageRemove(JWT.refreshTokenName);
    JWT.storageRemove(JWT.username);
  }

  static async retrieve() {
    try {
      this.verify();
    } catch (validationException) {
      // Token is not valid, let's try to obtain a new one
      await JWT.regenerate();
    }

    return JWT.getToken();
  }
}
