/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
  {
    icon: "mdi-map-marker",
    text: "Локації",
    link: "/locations",
  },
  {
    icon: "mdi-web",
    text: "Мови",
    link: "/languages",
  },
  {
    icon: "mdi-account",
    text: "Користувачі",
    link: "/users",
  },
  // admin.getResourceLink("locations"),
  // admin.getResourceLink("languages"),
  // admin.getResourceLink("users"),
  { divider: true },
];
