import BaseModel from './BaseModel';
import loginUserMutation from "../gql/users/mutations/loginUser.graphql";

export default class Login extends BaseModel {
  email: string;

  password: string;

  defaults() {
    return {
      email: '',
      password: '',
    };
  }

  login() {
    // @ts-ignore
    const { email, password } = this;

    return this.mutate(loginUserMutation, {
      logInUser: {
        email,
        password,
      }
    })
  }
}
