import Vue from "vue";
import VuetifyAdmin from "vuetify-admin";

import "vuetify-admin/src/loader";

// import authProvider from "@/providers/authProvider";

import {
  fakeAuthProvider,
} from "vuetify-admin/src/providers";
import { en } from "vuetify-admin/src/locales";
// import { uk } from "vuetify-admin/src/locales";

import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";
// import axios from "axios";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);


/**
 * Init admin
 */
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "Vuetify Admin",
  routes,
  locales: {
    en,
  },
  translations: ["en"],
  dataProvider: {
    getList:    () => {
      return {}
    },
    getOne:     () => {},
    getMany:    () => {},
    create:     () => {},
    update:     () => {},
    updateMany: () => {},
    delete:     () => {},
    deleteMany: () => {},
  },
  authProvider: fakeAuthProvider(),
  resources,
  options: {
    dateFormat: "long",
  },
});
