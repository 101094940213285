import Vue from "vue";
import AdminLayout from "@/layouts/Admin";
import Dashboard from "@/views/Dashboard";
import Error from "@/views/Error";
import i18n from "@/i18n";
import EditUser from "../resources/users/EditUser";
import EditLanguage from "../resources/languages/EditLanguage";
import EditLocation from "@/resources/locations/EditLocation";
/**
 * Error component
 */
Vue.component("Error", Error);

export default {
  path: "",
  component: AdminLayout,
  meta: {
    title: i18n.t("routes.home"),
  },
  children: [
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        title: i18n.t("routes.dashboard"),
      },
    },
    {
      path: "/user/:id/edit",
      name: "editUser",
      component: EditUser,
      meta: {
        title: i18n.t("routes.edit.user"),
      },
    },
    {
      path: "/language/:id/edit",
      name: "editLanguage",
      component: EditLanguage,
      meta: {
        title: i18n.t("routes.edit.language"),
      },
    },
    {
      path: "/location/:id/edit",
      name: "editLocation",
      component: EditLocation,
      meta: {
        title: i18n.t("routes.edit.location"),
      },
    },
    {
      path: "*",
      component: Error,
      meta: {
        title: i18n.t("routes.not_found"),
      },
    },
  ],
};
