import VueRouter from 'vue-router';
import MiddlewareException from '../../models/Exceptions/MiddlewareException';

import auth from './auth';

function carry(nextArg?: string) {
  // If undefined, resolve to proceed to next middleware
  // If NOT undefined, disrupt and not proceed to next middleware
  if (nextArg === undefined) {
    return;
  }
  throw new MiddlewareException(nextArg);
}

export default function initRouter(router: VueRouter) {
  router.beforeEach(async (to, from, next) => {

    try {
      // @ts-ignore
      await auth(to, from, carry);

    } catch (e) {
      return next(false);
    }

    return next();
  });

  return router;
}
