<template>
  <v-card>
    <v-card-title>
      <h1 class="display-1 h1">
        Користувачі
      </h1>
    </v-card-title>
    <v-card-text>
      <va-list
        hide-default-footer
        disable-export
        disable-global-search
      >
        <v-data-table
          :headers="headers"
          :items="users"
          hide-default-footer
        >
          <template v-slot:item.createdAt="{ item }">
            <v-chip class="green white--text">
              {{ new Date(+item.createdAt).toLocaleString() }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              color="blue darken-2"
              @click="editItem(item.uuid)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="!item.locations.length && !item.languages.length && item.email !== logInUserEmail"
              small
              color="red darken-2"
              @click="showModalDelete(item.name, item.uuid)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </va-list>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      max-width="400"
      :items="users"
    >
      <v-card>
        <v-card-title class="text-h5">
          Ви дійсно хочете видалити користувача "{{ userName }}"
        </v-card-title>
        <v-card-actions>
          <v-spacer />

          <v-btn
            color="green darken-1"
            text
            @click="deleteItem"
          >
            Так
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Ні
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import UserRepository from "../../repositories/UserRepository";

export default {
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Ім'я",
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {text: 'Email', value: 'email', sortable: false},
      {text: 'Коли додано', value: 'createdAt', sortable: false},
      {text: 'Дії', value: 'actions', sortable: false},
    ],
    userRepository: new UserRepository(),
    users: [],
    userName: '',
    userUuid: '',
    logInUserEmail: ''
  }),

  async created() {
    await this.userRepository.many();
    this.users = this.userRepository.dataset.all();
    this.logInUserEmail = this.$store.state.auth.user.name;
  },

  methods: {
    showModalDelete(userName, userUuid) {
      this.dialog = true
      this.userName = userName;
      this.userUuid = userUuid;
    },
    async deleteItem() {
      await this.users.find(u => u.uuid === this.userUuid).delete();
      this.users = this.users.filter(u => u.uuid !== this.userUuid);
      this.dialog = false;
    },
    editItem(uuid) {
      this.$router.push(`user/${uuid}/edit`);
    },

  },
};
</script>

