import BaseRepository from './BaseRepository';
import User from '../models/User';

export default class UserRepository extends BaseRepository<User> {
  model = User;

  // @ts-ignore
  fetchOneQuery = () => import('../gql/users/queries/fetchUser.graphql');

  // @ts-ignore
  fetchManyQuery = () => import('../gql/users/queries/fetchUsers.graphql');
}