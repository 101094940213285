export default [
  {
    name: "locations",
    icon: "mdi-location",
    label: "Локації",
  },
  {
    name: "languages",
    icon: "mdi-language",
    label: "Мови",
  },
  {
    name: "users",
    icon: "mdi-account",
    label: "Користувачі",
  },
];
