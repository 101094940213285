var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('h1',{staticClass:"display-1"},[_vm._v(" Локації ")])]),_c('v-card-text',[_c('va-list',{attrs:{"hide-default-footer":"","disable-export":"","disable-global-search":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.locations},scopedSlots:_vm._u([{key:"item.localizations",fn:function(ref){
var item = ref.item;
return [_c('v-card-text',[_vm._v(" "+_vm._s(_vm.getLocationName(item))+" ")])]}},{key:"item.imageUrl",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"location-image",attrs:{"src":item.imageUrl}})]}},{key:"item.isEnabled",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":"","color":"#000000"},model:{value:(item.isEnabled),callback:function ($$v) {_vm.$set(item, "isEnabled", $$v)},expression:"item.isEnabled"}})]}},{key:"item.isRecommended",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":"","color":"#000000"},model:{value:(item.isRecommended),callback:function ($$v) {_vm.$set(item, "isRecommended", $$v)},expression:"item.isRecommended"}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"green white--text"},[_vm._v(" "+_vm._s(new Date(Date.parse(item.createdAt)).toLocaleString())+" ")])]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('v-card-text',[_vm._v(" "+_vm._s(item.createdBy.name)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"blue darken-2"},on:{"click":function($event){return _vm.editItem(item.uuid)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":"","color":"red darken-2"},on:{"click":function($event){return _vm.showModalDelete(item.uuid)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"400","items":_vm.locations},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Ви дійсно хочете видалити цю Локацію ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.deleteItem}},[_vm._v(" Так ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Ні ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }