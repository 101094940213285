<template>
  <va-layout>
    <va-app-bar
      slot="app-bar"
      disable-reload
      disable-create
      dense
      dark
      title="Аудіо-гід - Луцьк"
      @toggle="
        $vuetify.breakpoint.lgAndUp ? (mini = !mini) : (drawer = !drawer)
      "
    />
    <va-sidebar
      slot="sidebar"
      v-model="drawer"
      :menu="sidebarMenu"
      :mini-variant="mini"
    />
    <template slot="header" />
    <va-aside slot="aside" />
    <va-footer slot="footer">
      &copy; 2021,
      <v-icon size="18">
        mdi-xml
      </v-icon>
      with
      <v-icon size="18">
        mdi-heart
      </v-icon>
      by
      <a href="https://www.google.com">Digital Idea</a> for a better web.
    </va-footer>
  </va-layout>
</template>

<script>
import nav from "../_nav";

export default {
  name: "App",
  data() {
    return {
      drawer: null,
      mini: false,
      sidebarMenu: nav(this.$i18n, this.$admin),
    };
  },
};
</script>
