import BaseModel from './BaseModel';
import User from "./User";
import Localization from "./Localization";
import createLocationMutation from '../gql/locations/mutations/createLocation.graphql';
import deleteLocationMutation from '../gql/locations/mutations/deleteLocation.graphql';
import updateLocationMutation from "../gql/locations/mutations/updateLocation.graphql";

export const LUTSK_POSITION = { lat: 50.74734154237352, lng: 25.324579628477217 };

export default class Location extends BaseModel {
  createMutation = createLocationMutation;
  deleteMutation = deleteLocationMutation;
  updateMutation = updateLocationMutation;

  imageUrl: string;

  isRecommended: boolean;

  createdAt: Date;

  createdBy: User;

  isEnabled: boolean;

  typeNum: number;

  localizations: Localization[];

  latLng: { lat: string, lng: string };

  defaults() {
    return {
      createdAt: null,
      createdBy: null,
      latLng: null,
      localizations: [],
      isEnabled: false,
      isRecommended: false,
      imageUrl: '',
      typeNum: 0,
    };
  };
}
