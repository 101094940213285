import Vue from "vue";
import VueOOP from "vue-oop";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import admin from "./plugins/admin";
import "./plugins/i18n";
import "./sass/overrides.sass";

Vue.config.productionTip = false;
Vue.use(VueOOP, {
  graphql: true,
  schemaUrl: process.env.VUE_APP_API_ENDPOINT_GRAPHQL,
  httpEndpoint: process.env.VUE_APP_API_ENDPOINT_GRAPHQL,
});


new Vue({
  router,
  store,
  // @ts-ignore
  i18n,
  vuetify,
  admin,
  render: (h) => h(App),
}).$mount("#app");
