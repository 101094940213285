<template>
  <div>
    <v-card-title class="text--primary mb-2">
      Редагувати Мову
    </v-card-title>
    <v-form
      :id="id"
      ref="form"
    >
      <v-row justify="center">
        <v-col sm="6">
          <v-card>
            <v-card-text>
              <v-col>
                <v-text-field
                  v-model="languageUpdate.title"
                  filled 
                  :error-messages="errorMessages.title"
                  label="Назва мови"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="languageUpdate.code"
                  filled
                  label="Код мови"
                />
              </v-col>
              <v-col>
                <va-boolean-input
                  v-model="languageUpdate.isEnabled"
                  filled
                  label="Активно"
                />
              </v-col>
              <v-btn
                depressed
                color="primary"
                @click="updateLanguage"
              >
                Змінити
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-alert
        v-if="isUpdated"
        class="message-success"
        dense
        type="success"
      >
        Мову успішно змінено
      </v-alert>
    </v-form>
  </div>
</template>

<script>
import LanguageRepository from "../../repositories/LanguageRepository";
import Language from "../../models/Language";

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["id", "title", "item"],
  data: () => ({
    language: new Language(),
    errorMessages: {},
    isUpdated: false,
    languageRepository: new LanguageRepository(),
    languageUpdate: {},
  }),
  async created() {
    this.languageUpdate = await this.languageRepository.one(this.$route.params.id);
  },
  methods: {
    async updateLanguage() {
      try {
        this.language.uuid =  this.$route.params.id;
        this.language.title = this.languageUpdate.title;
        this.language.code = this.languageUpdate.code;
        this.language.isEnabled = this.languageUpdate.isEnabled;

        await this.language.update();
        this.isUpdated = true;
        setTimeout(() => {
          this.isUpdated = false, this.$router.push({path: `/languages`})
        }, 1000);
      } catch (e) {
        if (e.errors) {
          this.errorMessages = e.errors;
          return;
        }
        this.errorMessages = {title: "Така Мова вже існує"};
      }
    },
  },
};
</script>

<style>
.message-success {
  display: block;
  position: absolute;
  bottom: 70px;
  width: 98%;
  text-align: center;
  margin: auto;
}
</style>
