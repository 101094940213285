<template>
  <v-card>
    <v-card-title>
      <h1 class="display-1">
        Мови
      </h1>
    </v-card-title>
    <v-card-text>
      <va-list
        hide-default-footer
        disable-export
        disable-global-search
      >
        <v-data-table
          :headers="headers"
          :items="languages"
          hide-default-footer
        >
          <template v-slot:item.isEnabled="{ item }">
            <v-checkbox
              v-model="item.isEnabled"
              readonly
              color="#000000"
            />
          </template>
          <template v-slot:item.createdAt="{ item }">
            <v-chip class="green white--text">
              {{ new Date(Date.parse(item.createdAt)).toLocaleString() }}
            </v-chip>
          </template>
          <template v-slot:item.createdBy="{ item }">
            <v-card-text>
              {{ item.createdBy.name }}
            </v-card-text>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              color="blue darken-2"
              @click="editItem(item.uuid)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="item.code !== 'uk' && item.code !== 'ua'"
              small
              color="red darken-2"
              @click="showModalDelete(item.title, item.uuid)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </va-list>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      max-width="400"
      :items="languages"
    >
      <v-card>
        <v-card-title class="text-h5">
          Ви дійсно хочете видалити мову "{{ languageName }}"
        </v-card-title>
        <v-card-actions>
          <v-spacer />

          <v-btn
            color="green darken-1"
            text
            @click="deleteItem"
          >
            Так
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Ні
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import LanguageRepository from "../../repositories/LanguageRepository";

export default {
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Назва мови",
        align: 'start',
        sortable: false,
        value: 'title',
      },
      {text: 'Код мови', value: 'code', sortable: false},
      {text: 'Активно', value: 'isEnabled', sortable: false},
      {text: 'Коли додано', value: 'createdAt', sortable: false},
      {text: 'Ким додано', value: 'createdBy', sortable: false},
      {text: 'Дії', value: 'actions', sortable: false},
    ],
    languageRepository: new LanguageRepository(),
    languages: [],
    languageName: '',
    languageUuid: '',
  }),

  async created() {
    await this.languageRepository.many();
    this.languages = this.languageRepository.dataset.all();
  },

  methods: {
    showModalDelete(languageName, languageUuid) {
      this.dialog = true;
      this.languageName = languageName;
      this.languageUuid = languageUuid;
    },
    async deleteItem() {
      await this.languages.find(u => u.uuid === this.languageUuid).delete();
      this.languages = this.languages.filter(u => u.uuid !== this.languageUuid);
      this.dialog = false;
    },
    editItem(uuid) {
      this.$router.push(`language/${uuid}/edit`);
    },
  },
};
</script>
