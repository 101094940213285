<template>
  <div>
    <div class="d-flex justify-space-between">
      <h2 class="ml-5 mb-6">
        Редагувати Локацію
      </h2>
      <template>
        <v-menu
          offset-y
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              color="primary"
              class="white--text"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2">
                mdi-translate
              </v-icon>
              Локалізація: {{ localizationBtnTitle }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(language, index ) in languages"
              :key="index"
              class="row-pointer"
            >
              <v-list-item-title
                @click="addLocalization(language.title, language.uuid)"
              >
                {{ language.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </div>
    <va-form
      :id="id"
      ref="form"
      :item="item"
    >
      <v-row justify="center">
        <v-col sm="9">
          <v-card-text>
            <va-text-input
              v-if="getActiveLocalization"
              v-model="getActiveLocalization.locationTitle"
              source="Заголовок"
            />
            <v-textarea
              v-if="getActiveLocalization"
              v-model="getActiveLocalization.locationDescription"
              counter
              filled
              label="Опис"
              maxlength="150"
            ></v-textarea>
            <v-file-input
              v-if="getActiveLocalization"
              v-model="currentLanguageAudio"
              filled
              placeholder="Змінити аудіо"
              prepend-icon="mdi-account-music"
            />
            <v-select
              v-model="locationType"
              :items="locationTypes"
              placeholder="Вибрати тип"
            >
              <template
                slot="selection"
                slot-scope="data"
              >
                <v-list-item disabled>
                  <v-list-item-avatar color="grey lighten-3">
                    <v-icon color="#000000">
                      {{ data.item.icon }}
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title color="#000000">
                      {{ data.item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template
                slot="item"
                slot-scope="data"
              >
                <v-list-item-avatar color="grey lighten-3">
                  <v-icon color="#000000">
                    {{ data.item.icon }}
                  </v-icon>
                </v-list-item-avatar>

                <h3>
                  {{ data.item.title }}
                </h3>
              </template>
            </v-select>
            <va-boolean-input
              v-model="locationUpdate.isEnabled"
              source="Активно"
            />
            <va-boolean-input
              v-model="locationUpdate.isRecommended"
              label="Рекомендовано"
            />
            <v-file-input
              v-model="locationImageFile"
              filled
              placeholder="Змінити зображення"
              prepend-icon="mdi-camera"
            />
            <template>
              <div
                id="google-map"
                ref="googleMap"
                class="google-map"
              />
            </template>
          </v-card-text>
          <v-btn
            depressed
            color="primary"
            :loading="loading"
            @click="updateLocation"
          >
            Змінити
          </v-btn>
        </v-col>
        <v-alert
          v-if="isUpdated"
          type="success"
          class="message-success"
          dense
        >
          Локацію успішно змінено
        </v-alert>
      </v-row>
    </va-form>
  </div>
</template>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";
import Location, { LUTSK_POSITION } from "../../models/Location";
import LanguageRepository from "../../repositories/LanguageRepository";
import LocationRepository from "@/repositories/LocationRepository";
import { v4 as uuid } from 'uuid';
import storageService from '@/services/storageService';
import { Localization } from '@/models';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["id", "title", "item"],
  data: () => ({
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
    google: null,
    map: null,
    marker: null,
    location: new Location(),
    locationTitle: '',
    locationDescription: '',
    locationImageFile: [],
    locationAudioFile: [],
    isUpdated: false,
    languageRepository: new LanguageRepository(),
    locationRepository: new LocationRepository(),
    languages: [],
    localizationBtnTitle: 'Ukrainian',
    languagesObj: {},
    languageUuid: '',
    locationsUpdate: [],
    locationUpdate: {},
    loading: false,
    locationType: {
      title: '',
      icon: '',
      typeNum: 0,
    },
    locationTypes: [
      {
        title: "Оборонні об’єкти",
        icon: 'mdi-chess-rook',
        typeNum: 1,
      },
      {
        title: "Культові об’єкти",
        icon: 'mdi-church',
        typeNum: 2,
      },
      {
        title: "Господарсько-економічні об’єкти",
        icon: 'mdi-flag-variant',
        typeNum: 3,
      },
      {
        title: "Культурні об’єкти",
        icon: 'mdi-bank',
        typeNum: 4,
      },
    ],
  }),
  computed: {
    getActiveLocalization() {
      if (!this.location.localizations) return;
      return this.location.localizations.find(l => l.languageUuid === this.languageUuid);
    },
    currentLanguageAudio: {
      get() {
        if (this.location.localizations) return;
        return this.locationAudioFile.find(f => f.name);
      },
      set(audio) {
        this.locationAudioFile.push(audio);
        this.getActiveLocalization.audioFile = audio;
      },
    },
  },
  async created() {
    await this.locationRepository.many();
    this.locationsUpdate = this.locationRepository.dataset.all();
    this.locationUpdate = this.locationsUpdate.find(l => l.uuid === this.$route.params.id);
    this.locationType = {
      title: this.locationUpdate.typeNum === 1
        ? 'Оборонні об’єкти' : this.locationUpdate.typeNum === 2
          ? 'Культові об’єкти' : this.locationUpdate.typeNum === 3
            ? 'Господарсько-економічні об’єкти' : this.locationUpdate.typeNum === 4
              ? 'Культурні об’єкти' : '',
      icon: this.locationUpdate.typeNum === 1
        ? 'mdi-chess-rook' : this.locationUpdate.typeNum === 2
          ? 'mdi-church' : this.locationUpdate.typeNum === 3
            ? 'mdi-flag-variant' : this.locationUpdate.typeNum === 4
              ? 'mdi-bank' : '',
      typeNum: this.locationUpdate.typeNum,
    };
    await this.languageRepository.many();
    this.languages = this.languageRepository.dataset.all();
    this.languagesObj = this.languages.reduce((a, v) => ({
      ...a,
      [v.code]: v,
    }), {});
    this.languageUuid = this.languagesObj.uk.uuid;
    this.locationUpdate.localizations.forEach(l => {
      this.location.localizations.push(new Localization({
        uuid: l.uuid,
        languageUuid: l.language.uuid,
        locationTitle: l.locationTitle,
        locationDescription: l.locationDescription,
        locationUuid: this.$route.params.id,
        audioUrl:  this.getPathName(l.audioUrl).slice(1),
      }));
    });
    this.languages.forEach(l => {
      let currentLangUuid = this.locationUpdate.localizations.find(lang => lang.language.uuid === l.uuid) ?
        this.locationUpdate.localizations.find(lang => lang.language.uuid === l.uuid).language.uuid : '';

      if (l.uuid === currentLangUuid) return;
        this.location.localizations.push(new Localization({
          languageUuid: l.uuid,
          locationTitle: '',
          locationDescription: '',
          locationUuid: this.$route.params.id,
          audioUrl: '',
        }));
    });
    this.google = await GoogleMapsApiLoader({
      apiKey: this.apiKey,
    });
    this.initializeMap();
  },
  methods: {
    initializeMap() {
      const lutsk = { lat: 50.74734154237352, lng: 25.324579628477217 };
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, {
        zoom: 14,
        center: lutsk,
      });
      this.createMarker({ lat: +this.locationUpdate.latLng.lat, lng: +this.locationUpdate.latLng.lng }, this.map);
      this.map.addListener("click", (e) => {
        this.createMarker(e.latLng, this.map);
      });
    },
    getPathName(url) {
      return new URL(url).pathname;
    },
    createMarker(latLng, map) {
      if (!this.marker) {
        this.marker = new this.google.maps.Marker({
          position: latLng,
          map: map,
          draggable: true,
        });
      } else {
        this.marker.setPosition(latLng);
      }
    },
    addLocalization(title, uuid) {
      this.localizationBtnTitle = title;
      this.languageUuid = uuid;
      if (this.getActiveLocalization) return;
      this.locationTitle = '';
      this.locationDescription = '';
      this.locationAudioFile = [];
    },
    async updateLocation() {
      // Storage audio
      await Promise.all(this.location.localizations.map(async (localization) => {
        if (!localization.audioFile) {
          return Promise.resolve();
        }

        const filePath = `audio/${uuid()}.${localization.audioFile.name.split('.').pop()}`;
        const signedUrl = await storageService.getSignedUrlForPut(filePath);
        const options = {
          method: 'PUT',
          body: localization.audioFile,
        };

        await fetch(signedUrl, options);

        localization.audioUrl = filePath;
        delete localization.audioFile;
      }));

      this.location.uuid = this.$route.params.id;

      // Storage image
      this.location.imageUrl = '';

      if (this.locationImageFile && this.locationImageFile.name) {
        const filePath = `image/${uuid()}.${this.locationImageFile.name.split('.').pop()}`;
        const signedUrl = await storageService.getSignedUrlForPut(filePath);
        const options = {
          method: 'PUT',
          body: this.locationImageFile,
        };

        await fetch(signedUrl, options);

        this.location.imageUrl = filePath;
      } else {
        this.location.imageUrl = this.getPathName(this.locationUpdate.imageUrl).slice(1);
      }

      this.location.isEnabled = this.locationUpdate.isEnabled;
      this.location.isRecommended = this.locationUpdate.isRecommended;
      this.location.latLng = { lat: String(LUTSK_POSITION.lat), lng: String(LUTSK_POSITION.lng) };
      if (this.marker) {
        this.location.latLng = { lat: String(this.marker.position.lat()), lng: String(this.marker.position.lng()) };
      }
      this.location.typeNum = this.locationType.typeNum;
      await this.location.update();
      this.isUpdated = true;
      setTimeout(() => {
        this.isUpdated = false;
        this.$router.push({ path: `/locations` });
      }, 1000);
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.google-map {
  width:  100%;
  height: 300px;
}

.row-pointer {
  cursor: pointer;
}
</style>
