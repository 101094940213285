import BaseModel from './BaseModel';
import Language from './Language';
import Location from './Location'

export default class Localization extends BaseModel {

  audioFile: File;

  audioUrl: string;

  locationDescription: string;

  locationTitle: string;

  languageUuid: string;

  language: Language;

  location: Location

  defaults() {
    return {
      audioFile: null,
      audioUrl: '',
      locationDescription: '',
      locationTitle: '',
      location: null,
      language: null,
    };
  };
}
