import BaseModel from './BaseModel';
import User from './User';
import Localization from "./Localization";
import createLanguageMutation from '../gql/languages/mutations/createLanguage.graphql';
import deleteLanguageMutation from '../gql/languages/mutations/deleteLanguage.graphql';
import updateLanguageMutation from "../gql/languages/mutations/updateLanguage.graphql";

export default class Language extends BaseModel {
  createMutation = createLanguageMutation;
  deleteMutation = deleteLanguageMutation;
  updateMutation = updateLanguageMutation;

  code: string;

  createdAt: Date;

  createdBy: User;

  isEnabled: boolean;

  localizations: Localization[];

  title: string;

  defaults() {
    return {
      createdAt: null,
      createdBy: null,
      title: '',
      localizations: [],
      isEnabled: false,
      code: '',
    };
  };
}
