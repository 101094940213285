<template>
  <v-card>
    <v-card-title>
      <h1 class="display-1">
        Локації
      </h1>
    </v-card-title>
    <v-card-text>
      <va-list
        hide-default-footer
        disable-export
        disable-global-search
      >
        <v-data-table
          :headers="headers"
          :items="locations"
        >
          <template v-slot:item.localizations="{ item }">
            <v-card-text>
              {{ getLocationName(item) }}
            </v-card-text>
          </template>
          <template v-slot:item.imageUrl="{ item }">
            <v-img
              :src="item.imageUrl"
              class="location-image"
            />
          </template>
          <template v-slot:item.isEnabled="{ item }">
            <v-checkbox
              v-model="item.isEnabled"
              readonly
              color="#000000"
            />
          </template>
          <template v-slot:item.isRecommended="{ item }">
            <v-checkbox
              v-model="item.isRecommended"
              readonly
              color="#000000"
            />
          </template>
          <template v-slot:item.createdAt="{ item }">
            <v-chip class="green white--text">
              {{ new Date(Date.parse(item.createdAt)).toLocaleString() }}
            </v-chip>
          </template>
          <template v-slot:item.createdBy="{ item }">
            <v-card-text>
              {{ item.createdBy.name }}
            </v-card-text>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              color="blue darken-2"
              @click="editItem(item.uuid)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              color="red darken-2"
              @click="showModalDelete(item.uuid)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </va-list>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      max-width="400"
      :items="locations"
    >
      <v-card>
        <v-card-title class="text-h5">
          Ви дійсно хочете видалити цю Локацію ?
        </v-card-title>
        <v-card-actions>
          <v-spacer />

          <v-btn
            color="green darken-1"
            text
            @click="deleteItem"
          >
            Так
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Ні
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import LocationRepository from "../../repositories/LocationRepository";

export default {
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Назва",
        align: 'start',
        sortable: false,
        value: 'localizations',
      },
      {text: 'Зображення', value: 'imageUrl', sortable: false},
      {text: 'Рекомендовано', value: 'isRecommended', sortable: false},
      {text: 'Активно', value: 'isEnabled', sortable: false},
      {text: 'Коли додано', value: 'createdAt', sortable: false},
      {text: 'Ким додано', value: 'createdBy', sortable: false},
      {text: 'Дії', value: 'actions', sortable: false},
    ],
    locationRepository: new LocationRepository(),
    locations: [],
    localizationObj: {},
    locationUuid: '',
  }),
  async created() {
    await this.locationRepository.many();
    this.locations = this.locationRepository.dataset.all();
  },
  methods: {
    getLocationName(item) {
      return item.localizations.find(l => l.language.code === "uk").locationTitle;
    },
    showModalDelete(locationUuid) {
      this.dialog = true;
      this.locationUuid = locationUuid;
    },
    async deleteItem() {
      await this.locations.find(u => u.uuid === this.locationUuid).delete();
      this.locations = this.locations.filter(u => u.uuid !== this.locationUuid);
      this.dialog = false;
    },
    editItem(uuid) {
      this.$router.push(`location/${uuid}/edit`);
    },
  },
};
</script>

<style scoped>
.location-image {
  width: 60px;
  height: 50px;
}
</style>
