<template>
  <v-form
    :id="id"
    ref="form"
    :item="item"
  >
    <v-row justify="center">
      <v-col sm="8">
        <v-card>
          <v-card-text>
            <v-text-field
              v-model="language.title"
              filled
              :error-messages="errorMessages.title"
              label="Назва мови"
              :rules="rules.title"
            />
            <v-text-field
              v-model="language.code"
              filled
              :error-messages="errorMessages.code"
              label="Код мови"
              :rules="rules.code"
            />
            <va-boolean-input
              v-model="language.isEnabled"
              label="Активно"
            />
            <v-btn
              depressed
              color="primary"
              @click="createNewLanguage"
            >
              Створити
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-alert
        v-if="isCreated"
        class="message-success"
        dense
        type="success"
      >
        Мову успішно створено
      </v-alert>
    </v-row>
  </v-form>
</template>

<script>
import Language from "../../models/Language";

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["id", "item"],
  data: () => ({
    language: new Language(),
    errorMessages: {},
    isCreated: false,
    rules: {
      title: [v => !!v  || 'Введіть назву мови'],
      code: [v => !!v  || 'Введіть код мови'],
    },
  }),
  methods: {
    async createNewLanguage() {
      if (this.$refs.form.validate()) {
        try {
          await this.language.create();
          this.isCreated = true;
          setTimeout(() => {
            this.isCreated = false;
            this.$router.push({path: `/languages`});
            this.$refs.form.reset();
          }, 1000);
        } catch (e) {
          if (e.errors) {
            this.errorMessages = e.errors;
            return;
          }
          this.errorMessages = {code: "Мова з таким кодом вже існує"};
        }
      }
    },
  },
};
</script>

<style>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  font-weight:bold;
  font-size:14px;
  color: #1976d2 !important;
}
</style>
