import { Route } from 'vue-router';
import { RawLocation } from 'vue-router/types/router';
export type NextFunction = (route?: RawLocation) => void | string;
import JWT from '../../tools/jwt';

export default async function auth(to: Route, from: Route, carry: NextFunction) {
  // Retrieve the token of the authorized user
  try {
    await JWT.retrieve();
  } catch (e) {
    // Add redirect to target auth needed route
    if (to.name === 'login') {
      return carry();
    }

    return carry({
      name: "login",
    });
  }

  // Continue routing if authenticated
  return carry();
}
