import BaseRepository from '../repositories/BaseRepository';
import fetchAwsSignUrl from '../gql/storage/queries/fetchAwsSignUrl.graphql';

// @ts-ignore
export class StorageService extends BaseRepository<string> {
  model = String;

  async forOperation(path: string, operation: string): Promise<string> {
    const storageInput = {
      operation,
      path,
    }
    return this.query(fetchAwsSignUrl, {
      storageInput,
    })
  }

  async getSignedUrlForPut(path: string): Promise<string> {
    return this.forOperation(path, 'putObject')
  }

  async getSignedUrlForGet(path: string): Promise<string> {
    return this.forOperation(path, 'getObject')
  }
}

export default new StorageService();
