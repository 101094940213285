<template>
  <div>
    <div class="d-flex justify-space-between">
      <h2 class="ml-5 mb-6">
        Нова Локація
      </h2>
      <template>
        <v-menu
          offset-y
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              color="primary"
              class="white--text"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2">
                mdi-translate
              </v-icon>
              Локалізація: {{ localizationBtnTitle }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(language, index ) in languages"
              :key="index"
              class="row-pointer"
            >
              <v-list-item-title
                @click="addLocalization(language.title, language.uuid)"
              >
                {{ language.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </div>
    <va-form
      :id="id"
      ref="form"
      :item="item"
    >
      <v-row justify="center">
        <v-col sm="9">
          <v-card-text>
            <va-text-input
              v-if="getActiveLocalization"
              v-model="getActiveLocalization.locationTitle"
              source="Заголовок"
            />
            <v-textarea
              v-if="getActiveLocalization"
              v-model="getActiveLocalization.locationDescription"
              counter
              filled
              label="Опис"
              maxlength="150"
            ></v-textarea>
            <v-file-input
              v-if="getActiveLocalization"
              v-model="currentLanguageAudio"
              filled
              placeholder="Вибрати аудіо"
              prepend-icon="mdi-account-music"
            />
            <v-select
              v-model="locationType"
              :items="locationTypes"
              placeholder="Вибрати тип"
            >
              <template
                slot="selection"
                slot-scope="data"
              >
                <v-list-item disabled>
                  <v-list-item-avatar color="grey lighten-3">
                    <v-icon color="#000000">
                      {{ data.item.icon }}
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title color="#000000">
                      {{ data.item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template
                slot="item"
                slot-scope="data"
              >
                <v-list-item-avatar color="grey lighten-3">
                  <v-icon color="#000000">
                    {{ data.item.icon }}
                  </v-icon>
                </v-list-item-avatar>

                <h3>
                  {{ data.item.title }}
                </h3>
              </template>
            </v-select>
            <va-boolean-input
              v-model="location.isEnabled"
              source="Активно"
            />
            <va-boolean-input
              v-model="location.isRecommended"
              label="Рекомендовано"
            />
            <v-file-input
              v-model="locationImageFile"
              filled
              placeholder="Вибрати зображення"
              prepend-icon="mdi-camera"
            />
            <template>
              <div
                id="google-map"
                ref="googleMap"
                class="google-map"
              />
            </template>
          </v-card-text>
          <v-btn
            :loading="loading"
            depressed
            color="primary"
            @click="createNewLocation"
          >
            Створити
          </v-btn>
        </v-col>
        <v-alert
          v-if="isCreated"
          class="message-success"
          dense
          type="success"
        >
          Локацію успішно створено
        </v-alert>
      </v-row>
    </va-form>
  </div>
</template>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";
import Location, { LUTSK_POSITION } from "@/models/Location";
import LanguageRepository from "@/repositories/LanguageRepository";
import { Localization } from '@/models';
import storageService from '@/services/storageService';
import { v4 as uuid } from 'uuid';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["id", "title", "item"],
  data: () => ({
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
    google: null,
    map: null,
    marker: null,
    location: new Location(),
    locationTitle: '',
    locationDescription: '',
    locationImageFile: [],
    locationAudioFile: [],
    isCreated: false,
    languageRepository: new LanguageRepository(),
    languages: [],
    localizationBtnTitle: 'Ukrainian',
    languagesObj: {},
    localizationObj: {},
    languageUuid: '',
    loading: false,
    locationType: {},
    locationTypes: [
      {
        title: "Оборонні об’єкти",
        icon: 'mdi-chess-rook',
        typeNum: 1,
      },
      {
        title: "Культові об’єкти",
        icon: 'mdi-church',
        typeNum: 2,
      },
      {
        title: "Господарсько-економічні об’єкти",
        icon: 'mdi-flag-variant',
        typeNum: 3,
      },
      {
        title: "Культурні об’єкти",
        icon: 'mdi-bank',
        typeNum: 4,
      },
    ],
  }),
  computed: {
    getActiveLocalization() {
      if (!this.location.localizations) return null;
      return this.location.localizations.find(l => l.languageUuid === this.languageUuid);
    },
    currentLanguageAudio: {
      get() {
        if (!this.location.localizations) return;
        return this.locationAudioFile.find(f => f.name);
      },
      set(audio) {
        this.locationAudioFile.push(audio);
        this.getActiveLocalization.audioFile = audio;
      },
    },
  },
  async mounted() {
    this.google = await GoogleMapsApiLoader({
      apiKey: this.apiKey,
    });
    this.initializeMap();
  },

  async created() {
    await this.languageRepository.many();
    this.languages = this.languageRepository.dataset.all();
    this.languagesObj = this.languages.reduce((a, v) => ({
      ...a,
      [v.code]: v,
    }), {});
    this.languageUuid = this.languagesObj.uk.uuid;
    this.location.localizations.push(new Localization({
      languageUuid: this.languageUuid,
      locationTitle: '',
      locationDescription: '',
      audioUrl: '',
    }));
  },
  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, {
        zoom: 14,
        center: LUTSK_POSITION,
      });
      this.map.addListener("click", (e) => {
        this.createMarker(e.latLng, this.map);
      });
    },
    createMarker(latLng, map) {
      if (!this.marker) {
        this.marker = new this.google.maps.Marker({
          position: latLng,
          map: map,
          draggable: true,
        });
      } else {
        this.marker.setPosition(latLng);
      }
    },
    addLocalization(title, uuid) {
      this.localizationBtnTitle = title;
      this.languageUuid = uuid;
      if (this.getActiveLocalization) return;
      this.location.localizations.push(new Localization({
        languageUuid: uuid,
        locationTitle: '',
        locationDescription: '',
        audioFile: '',
        audioUrl: '',
      }));
      this.locationTitle = '';
      this.locationDescription = '';
      this.locationAudioFile = [];
    },

    async createNewLocation() {
      // Storage audio
      await Promise.all(this.location.localizations.map(async (localization) => {
        localization.audioUrl = '';

        if (!localization.audioFile) {
          return Promise.resolve();
        }

        const filePath = `audio/${uuid()}.${localization.audioFile.name.split('.').pop()}`;
        const signedUrl = await storageService.getSignedUrlForPut(filePath);
        const options = {
          method: 'PUT',
          body: localization.audioFile,
        };

        await fetch(signedUrl, options);

        localization.audioUrl = filePath;
        delete localization.audioFile;
      }));

      // Storage image
      this.location.imageUrl = '';

      if (this.locationImageFile) {
        const filePath = `image/${uuid()}.${this.locationImageFile.name.split('.').pop()}`;
        const signedUrl = await storageService.getSignedUrlForPut(filePath);
        const options = {
          method: 'PUT',
          body: this.locationImageFile,
        };

        await fetch(signedUrl, options);

        this.location.imageUrl = filePath;
      }

      this.location.typeNum = this.locationType.typeNum;

      this.location.latLng = { lat: String(LUTSK_POSITION.lat), lng: String(LUTSK_POSITION.lng) };
      if (this.marker) {
        this.location.latLng = { lat: String(this.marker.position.lat()), lng: String(this.marker.position.lng()) };
      }
      await this.location.create();

      this.isCreated = true;

      setTimeout(() => {
        this.isCreated = false;
        this.$router.push({ path: `/locations` });
      }, 1000);

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.google-map {
  width:  100%;
  height: 300px;
}

.row-pointer {
  cursor: pointer;
}
</style>
