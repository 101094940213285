<template>
  <v-form
    ref="form"
    @submit.prevent="logIn"
  >
    <v-text-field
      v-model="logInUser.email"
      :label="$t('auth.email')"
      prepend-icon="mdi-account"
      required
      :error-messages="errorMessages.email"
    />

    <v-text-field
      v-model="logInUser.password"
      :label="$t('auth.password')"
      prepend-icon="mdi-lock"
      type="password"
      required
      :error-messages="errorMessages.password"
    />

    <div class="text-center">
      <v-btn
        :loading="loading"
        color="primary"
        large
        type="submit"
        text
        rounded
      >
        {{ $t("auth.sign_in") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import {mapActions} from "vuex";
import Login from "../../models/Login";
import JWT from "../../tools/jwt";

export default {
  data() {
    return {
      logInUser: new Login(),
      errorMessages: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    async logIn() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          const {accessToken, refreshToken} = await this.logInUser.login();
          if (accessToken, refreshToken) {
            this.saveTokens(accessToken, refreshToken);
            await this.login({
              username: this.logInUser.email,
              password: this.logInUser.password
            });
          }
        } catch (e) {
          if (e.errors) {
            this.errorMessages = e.errors;
            return;
          }
          this.errorMessages = {
            email: "Неправильний Email або пароль",
            password: "Неправильний Email або пароль"
          };
        } finally {
          this.loading = false;
        }
      }
    },
    saveTokens(accessToken, refreshToken) {
      JWT.storageSet(JWT.tokenName, accessToken);
      JWT.storageSet(JWT.refreshTokenName, refreshToken);
    },
  },
};
</script>

<style scoped>
#login-page {
  background-color: var(--v-primary-lighten5);
}
</style>
