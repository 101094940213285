<template>
  <div>
    <h2 class="ml-5 mb-6">
      Новий Користувач
    </h2>
    <users-form :itemid="item" />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["title", "item"],
};
</script>
