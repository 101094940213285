import BaseModel from './BaseModel';
import Language from './Language';
import Location from './Location';
import createUserMutation from '../gql/users/mutations/createUser.graphql';
import deleteUserMutation from '../gql/users/mutations/deleteUser.graphql';
import updateUserMutation from "../gql/users/mutations/updateUser.graphql";

export default class User extends BaseModel {
  createMutation = createUserMutation;
  deleteMutation = deleteUserMutation;
  updateMutation = updateUserMutation;

  createdAt: string;

  email: string;

  languages: Language[];

  locations: Location[];

  name: string;

  defaults() {
    return {
      createdAt: '',
      email: '',
      languages: [],
      locations: [],
      name: '',
    };
  };

  fetchToken(mutation, queryVariables) {
    return this.mutate(mutation, queryVariables);
  }
};
