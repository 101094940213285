import { RawLocation } from 'vue-router';
import BaseException from './BaseException';

export default class MiddlewareException extends BaseException {
  message = 'MiddlewareException';

  route: string | RawLocation | undefined = undefined;

  constructor(route: string | RawLocation | undefined = undefined) {
    super();
    this.route = route;
  }
}
